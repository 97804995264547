<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.acttiv.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            outlined
            v-model="hotelID"
            :label="$t('dtouch.workspace.acttiv.hotel', locale)"
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-checkbox
            v-model="isTestUrl"
            :label="$t('dtouch.workspace.acttiv.isTestUrl', locale)"
            hide-details
            @change="handleChange"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-checkbox
            v-model="isBook"
            :label="$t('dtouch.workspace.acttiv.isBook', locale)"
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            outlined
            v-model="acttivImage"
            :label="$t('dtouch.workspace.acttiv.image', locale)"
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'CustomURLBackForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    hotelID: null,
    isTestUrl: false,
    isBook: false,
    acttivImage: 'genericas',
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/acttiv`)
        .then(response => {
          if (response) {
            this.hotelID = response.HotelID
            this.isTestUrl = response.IsTestUrl ? true : false
            this.isBook = response.IsBook ? true : false
            this.acttivImage = response.ActtivImage
          }
        })
    },
    handleExpand () {
      if(!this.hotelID && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        HotelID: this.hotelID,
        IsTestUrl: this.isTestUrl,
        IsBook: this.isBook,
        ActtivImage: this.acttivImage,
      })
    }
  },
}
</script>

